import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./index.scss";

export default function ActivityHeader({ heading, backgroundImage, ...props }) {
    return (
        <div className="ActivityHeader" {...props}>
            <div className="ActivityHeader__container">
                <GatsbyImage className="ActivityHeader__image" image={backgroundImage} alt={heading} />
                <div className="ActivityHeader__heading-container">
                    <h1 className="ActivityHeader__heading">{heading}</h1>
                </div>
            </div>
        </div>
    );
}