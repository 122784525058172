import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useI18n } from "@lib/i18n/i18n-react";
import createFormPersist from "@lib/react-hook-form-persist";
import { bookingFormValidations as validations } from "@lib/validations/form-validations";
import { bookingForm as bookingConsts } from "@lib/validations/validation-constants";
import { inputFromFrontmatter, registerInputForForm } from "@lib/utils/form-utils";
import { InputGroup, Input, apiUrl } from "@components/forms";
import ValidatedForm, { FormState } from "@components/validated-form";
import "./index.scss";



export default function BookingForm({ activity, activityName, additionalFields = [] }) {
    const { t, language } = useI18n("bookingForm");

    const stateLabels = {
        [FormState.INITIAL]: t(".labels.submit.initial"),
        [FormState.SUBMITTING]: t(".labels.submit.submitting"),
        [FormState.SUBMITTED]: t(".labels.submit.submitted"),
        [FormState.INVALID]: t(".labels.submit.invalid"),
        [FormState.ERROR]: t(".labels.submit.error"),
    }

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    useEffect(() => {
        // Date in Prague.
        // Note: ignores daylight savings, but min date is generous anyway.
        setMinDate(bookingConsts.date.min());
        setMaxDate(bookingConsts.date.max());
    }, []);

    const { register, watch, setValue, formState: {errors}, handleSubmit } = useForm({ shouldUseNativeValidation: true });

    const useFormPersist = createFormPersist("BookingForm", { exclude: ["activity", "privacy"] });
    const { clear: clearSavedForm } = useFormPersist({ watch, setValue });

    const registerInput = registerInputForForm("BookingForm", register, t, errors, validations);
    const getOptionalInput = inputFromFrontmatter("BookingForm", register, t, errors);

    return (
        <div className="BookingForm">
            <div className="BookingForm__container">
                <ValidatedForm
                    onSuccess={clearSavedForm}
                    onSubmit={handleSubmit}
                    url={apiUrl("booking", language)}
                    redirectUrl="/success/booking"
                    stateLabels={stateLabels}
                    exclude={["day", "month", "year"]}
                    submitErrorMessage={(reason) => t(".submitErrorMessage", { reason })}
                >
                    <h2 className="BookingForm__heading">{t(".heading")}</h2>

                    <Input.Hidden ref={register} name="activity" defaultValue={activityName} />

                    <InputGroup.Horizontal>
                        <Input.Text {...registerInput("fullName")} required />
                        <Input.Email {...registerInput("email")} required />

                        <Input.Date {...registerInput("date")} required min={minDate} max={maxDate} />
                        {/* <Input.Time {...registerInput("time")} required step={bookingConsts.time.step * 60} /> */}

                        {/* <Input.Number {...registerInput("persons")} required min={bookingConsts.persons.min} /> */}

                        {(additionalFields !== null) && additionalFields.map(getOptionalInput)}
                    </InputGroup.Horizontal>

                    <Input.Textarea {...registerInput("message")} placeholder={t(".labels.messagePlaceholder")} rows={6} />

                    <InputGroup>
                        <Input.Checkbox {...registerInput("privacy")} required />
                    </InputGroup>

                    <Input.Honeypot t={t} registerInput={registerInput} />
                </ValidatedForm>
            </div>
        </div>
    );
}
