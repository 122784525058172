import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { getImage } from "gatsby-plugin-image";
import { I18nConsumer } from "@lib/i18n/i18n-react";
import Layout from "@components/_layout";
import ActivityHeader from "@components/activity-header";
import ActivityQuickInfo from "@components/activity-quick-info";
import BookingForm from "@components/booking-form";
import MarkdownContent from "@components/markdown/markdown-content";
import "./activities.scss";

export default function ActivityLayout({ pageContext,
  data: {
    mdx: {
      frontmatter: { title, description, openingHours, basePrice, location, headerImage, images, additionalInputs },
      fields: { slug },
      body,
    }
  }
}) {

  useEffect(() => {
    if (window.refreshFsLightbox)
      window.refreshFsLightbox();
  }, []);

  return (
    <Layout title={title} description={description} image={headerImage} {...pageContext}>

      <I18nConsumer>
        {({ t }) =>
          <div className="ActitivityPage">

            <main id="main">
              <header className="ActivityPage__header">
                <ActivityHeader
                  heading={title}
                  backgroundImage={getImage(headerImage)} />

                <ActivityQuickInfo
                  openingHours={openingHours}
                  price={basePrice}
                  location={location} />
              </header>

              <div className="ActivityPage__content">
                <MarkdownContent images={images} headingOffset={1}>
                  {body}
                </MarkdownContent>
              </div>

              <section className="ActitivityPage__booking">
                <BookingForm activity={slug} activityName={title} additionalFields={additionalInputs} />
              </section>
            </main>

            <aside className="ActivityPage__contact">
              <h2>{t("activity.questionsHeading")}</h2>
              <p>{t("activity.questionsText1")}</p>
              <p>{t("activity.questionsText2")}</p>
            </aside>

          </div>
        }
      </I18nConsumer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Activity($id: String!) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        openingHours
        location
        basePrice
        headerImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        additionalInputs {
          name
          type
          label
          required
          options
          step
          min
          max
        }
        images {
          name
          title
          file {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
      body
      fields {
        filePath
        slug
      }
    }
  }
`;